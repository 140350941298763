<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
            <van-field
                v-model="shousuoya"
                :rules="[{ required: true, message: '请输入' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">收缩压(SBP) <i>*</i></span>
                  <span style="display: flex;">
                   <span class="information">
                     <van-cell-group class="vanCell">
                       <van-field input-align="right" type="number" v-model="shousuoya" placeholder="请输入" />
                     </van-cell-group>
                   </span>
                    <div class="unit"  style="width: 45px"><span>mmHg</span></div>
                 </span>
                </div>
              </template>
            </van-field>
            <van-field
                v-model="shuzhangya"
                :rules="[{ required: true, message: '请输入' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">舒张压(DBP) <i>*</i></span>
                  <span style="display: flex;align-items: center;">
                   <span class="information">
                     <van-cell-group class="vanCell">
                       <van-field input-align="right" type="number" v-model="shuzhangya" placeholder="请输入" />
                     </van-cell-group>
                   </span>
                    <div class="unit"  style="width: 45px"><span>mmHg</span></div>
                 </span>
                </div>
              </template>
            </van-field>
            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button"  block type="info" native-type="submit" style="width:90px">提交</van-button>
            </div>
          </van-form>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span>{{countText}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults v-if="show" :countText="countText" :countTextList="countTextList"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      countTextList:[],
      shousuoya: '',
      shuzhangya: '',
      formulaCode: '',
      xueqingjigan: '',
      niaojigan: '',
      xueniaosudan: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      niaoniaosu: '',
    }
  },
  components: {
    FormulaExplain,
    FormulaBanner,
    MyPatient,
    FormulaReferences,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  watch: {
    "shousuoya": {
      immediate: true,
      handler: function () {
        this.show = false;
      }
    },
    "shuzhangya": {
      immediate: true,
      handler: function () {
        this.show = false;
      }
    },
  },

  methods:{
    niaoniaoVa () {
      return Number(this.niaoniaosu) <= 40076
    },
    xueniaosudanVa () {
      return !(Number(this.xueniaosudan) < 1 || Number(this.xueniaosudan) > 2102);
    },
    xueqingjiganVa () {
      return  !(Number(this.xueqingjigan) < 0.1 || Number(this.xueqingjigan) > 56.6);
    },
    niaojiganVa () {
      return Number(this.niaojigan) <= 1131.2;
    },
    coumputTetxt () {
      let gongshi = ''
      // let yingji = 0
      let jisuan = ''
      // if (this.xingbie == 1) { //男
      jisuan = (Number(this.shousuoya) + (2 * Number(this.shuzhangya))) / 3
      console.log(jisuan)
      jisuan = jisuan.toFixed(1)
      gongshi = `${jisuan}mmHg`
      // } else { //女
      //   jisuan = 655.1 + (9.563 * this.tizhong) + (1.850 * this.shengao) - (4.676 * this.nianling)
      // jisuan = Number(jisuan).toFixed(1)
      //   gongshi = `女性基础能量消耗：${jisuan}kcal/d`
      // }
      // this.yingjizhishuzhi = yingji
      this.result = jisuan
      this.countText = gongshi
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'XQPALDLA',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          // this.tableData = res.data
          // this.formulaCode = res.data.code
          this.shuzhangya = res.data.content.shuzhangya.value
          this.shousuoya = res.data.content.shousuoya.value
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.coumputTetxt()

          this.$nextTick(() => {
            this.show=true
          })
        }
      }
    },
    async submit () {
      this.coumputTetxt()
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shousuoya: {value: this.shousuoya, unit: 'mmHg'},
          shuzhangya: {value: this.shuzhangya, unit: 'mmHg'},
          // niaojigan: {value: this.niaojigan, unit: 'mg/dL'},
          // xueqingjigan: {value: this.xueqingjigan, unit: 'mg/dL'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          pingjundongmaiya: {value: this.result, unit: 'mmHg'},
          DisplayResults:this.countText
        }
      }
      // if (this.xingbie == 1) {
      //   data.result.nanxing = {value: this.result, unit: 'kcal'}
      // } else {
      //   data.result.nvxing = {value: this.result, unit: 'kcal'}
      // }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
        this.show=true
      } else {
        Toast.fail(res.message)
        this.show=true
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
